<template>
  <div>
    <p>
    <!-- MISSING: pic0E7DACDA01F105575B5C0A9981CCF193.jpg -->
    <img src="" class="img-small leftimage rounded">
    Fans of our ‘Build your LEGO Universe dreams’ series know the basics about these fun types of ingame LEGO building: Quick Builds, Modular Builds and Free Building. And if you have more questions about building in LEGO Universe, then Mads Purup has answers!
    </p>
    <p>
      A native of Denmark, Mads helps design the LEGO building experiences you’ll have when you become your LEGO Universe minifigure! He’s going to guide us through a few of the fun adventures you’ll discover in the gameworld!
    </p>
    <p>
      <strong>Hi Mads! What’s special about LEGO Universe <em>Free Building</em> and <em>Modular Building</em> compared to <em>Quick Building</em>?</strong>
    </p>
    <p>
      <em>Hello! You complete Quick Builds in the middle of the LEGO Universe gameplay action and the models are predetermined. With Modular Building, you have more freedom to customize LEGO models like rockets and racecars! And with LEGO Universe Free Building you can build whatever you want brick by brick!</em>
    </p>
    <p>
      <strong>Would you please give us an example of an ingame Modular Build?</strong>
    </p>
    <p>
      <em>Sure! Imagine that you’re exploring a massive spaceship when it runs into serious trouble. The situation is critical! You must escape the ship before it crashes!</em>
    </p>
    <p>
      <em>You see a Modular Building area where you can create a LEGO escape rocket—but first you need to go around the spaceship gathering the necessary modules! You’ll need at least one nose cone, one cockpit and one booster module, but you can collect several of each kind of module. Collecting more modules gives you more options for customizing your LEGO rocket!</em>
    </p>

    <!-- MISSING: pic15F727F02B5A3FFB6DF4402F9D6E9FB9.jpg -->
    <img src="" class="rounded">

    <p>
      <strong>Cool! How will I put together my LEGO escape rocket?</strong>
    </p>
    <p>
      <em>Once inside the rocket Modular Building area, your minifigure can enter a kind of creative bubble. You’ll remain on the spaceship, but the outside world fades. Now you’re free to take all the time you’d like building your custom escape rocket! Once you’re happy with your LEGO creation, you can escape from the crashing spaceship in style!</em>
    </p>
    <p>
      <strong>Where in LEGO Universe will I be able to Free Build?</strong>
    </p>
    <p>
      <em>In loads of places, so I’ll start with one of the first Free Build experiences players can have in the game. It’s a Free Building ‘showcase,’ which is a type of build where players can contribute content to LEGO Universe.</em>
    </p>
    <p>
      <em>Once you safely land your escape pod, you’ll battle and explore a bit before discovering a duck shooting gallery. The shooting gallery is similar to the ones you may have seen at fun fairs-- only here players are blasting apart LEGO models of ducks!</em>
    </p>
    <p>
      <em>Nearby, you see a Free Building area where you’re asked to build targets for the shooting gallery. When you enter the Free Building area, you can go back into your creative bubble and build your LEGO duck brick by brick! When you’re done, return to the shooting gallery and take aim at all the different duck models you and your friends made!</em>
    </p>

    <!-- MISSING: pic93701AD0C53C56E3633B55DB2C53A334.jpg -->
    <img src="" class="rounded">

    <p>
      Stay tuned to the LEGO Universe News Network for the second half of our exclusive interview with Mads Purup! When Mads returns, he’ll tell us about places in LEGO Universe where you can build and bring to life any model you can imagine!
    </p>
    <p>
      In the meantime, you can check out <strong>“Build your LEGO Universe dreams”</strong> editions <router-link :to="{ name: 'story', params: {id: 127606} }"><strong>Part 1</strong></router-link> and <router-link :to="{ name: 'story', params: {id: 141072} }"><strong>Part 2</strong></router-link> for more info on the build experiences Mads is helping create for the first Massively Multiplayer Online Game (MMOG) for LEGO fans!
    </p>
  </div>
</template>
